import { useMemo } from 'react';

import { TripsOfferData } from '@vakantiesnl/services/src/context/useAccommodationContext';
import { Search } from '@vakantiesnl/types';

export const useGetAccommodationOffer = (tripsOfferData: TripsOfferData): Search.OfferItem | undefined =>
	useMemo(() => {
		const departureDateOffers = !tripsOfferData?.loading ? tripsOfferData?.departureDateOffers : undefined;

		if (!departureDateOffers) {
			return undefined;
		}

		const selected = !tripsOfferData?.loading ? tripsOfferData?.selected : undefined;
		const highlight = !tripsOfferData?.loading ? tripsOfferData?.highlight : undefined;

		const prices = departureDateOffers.map(({ priceTotal }) => priceTotal);
		const min = Math.min(...prices);
		return (
			selected ||
			departureDateOffers.find(({ meta }) => meta?.departureDate === highlight) ||
			departureDateOffers.find(({ priceTotal }) => min === priceTotal)
		);
	}, [
		tripsOfferData?.departureDateOffers,
		tripsOfferData?.highlight,
		tripsOfferData?.loading,
		tripsOfferData?.selected,
	]);
