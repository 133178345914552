import { GeoEntity, DestinationType, Location } from '@vakantiesnl/types/src/elasticsearch/entities';

export const getLocations = (parents?: GeoEntity[]): Location[] => {
	return parents && parents.length
		? parents
				.map((parent): GeoEntity => {
					if (parent.destinationType === DestinationType.COUNTRY) return { ...parent, order: 1 };
					if (parent.destinationType === DestinationType.REGION) return { ...parent, order: 2 };
					if (parent.destinationType === DestinationType.CITY) return { ...parent, order: 3 };
					else return parent;
				})
				.sort((parent1: GeoEntity, parent2: GeoEntity) => {
					if (parent1.order && parent2.order) return parent1?.order - parent2?.order;
					else return 0;
				})
				.map(({ name, heliosUrl, id, destinationType, heliosId }) => ({
					destinationType,
					id,
					text: name,
					to: heliosUrl,
					heliosId,
				}))
		: [];
};
