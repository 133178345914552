import { useMemo } from 'react';

import { checkIfSsoTokenValid, getValueFromSsoToken, SSO_TOKEN_COOKIE } from '@vakantiesnl/services/src/util/authToken';
import Cookies from 'universal-cookie';

import { AvailabilityRequestBody } from './build';
import { State } from '../hooks/rest/build-booking-patch-request/reducer';
import { getClientIdFromGACookie } from '../util/googleClientId';

const getNewCheckAvailabilityRequest = (body: Record<string, unknown>): RequestInfo | undefined => {
	const spCookie = (document.cookie.split(';').filter((c) => c.indexOf('_sp_id') > -1)[0] || '').split('=')[1];
	const [duid, sid] = (spCookie || '').split('.').filter((_, i, all) => i === 0 || i === all.length - 1);

	/** Strip the version number from the _ga cookie */
	const gaCookie = getClientIdFromGACookie();

	const ssoToken = new Cookies().get(SSO_TOKEN_COOKIE);
	if (ssoToken && checkIfSsoTokenValid(ssoToken)) {
		// append only if there is ssoToken and it's valid (not expired)
		// otherwise - don't append at all
		body.user_id = getValueFromSsoToken(ssoToken, 'user_id');
	}

	return new Request(`${process.env.NEXT_PUBLIC_PACKAGE_ENDPOINT_URL}/api/v2/offer/availability`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...body,
			client_id: gaCookie,
			user_id_cookie: new Cookies().get('mcparam'),
			sid,
			duid,
		}),
	});
};

const getCheckAvailabilityRequestBySessionId = (sessionId: string, isPreBookCheck: boolean): RequestInfo =>
	`${process.env.NEXT_PUBLIC_PACKAGE_ENDPOINT_URL}/api/v2/offer/availability/${sessionId}${
		isPreBookCheck ? '?is_pre_book_check=true' : ''
	}`;

export const useCheckAvailabilityRequest = (
	body: AvailabilityRequestBody | undefined,
	isPreBookCheck = false,
): RequestInfo | undefined =>
	useMemo(() => {
		switch (typeof body) {
			case 'string':
				return getCheckAvailabilityRequestBySessionId(body, isPreBookCheck);
			case 'object':
				return getNewCheckAvailabilityRequest(body);
			default:
				return body;
		}
	}, [body, isPreBookCheck]);

export const createFetchBookingRequest = (sessionId: string): RequestInfo =>
	`${process.env.NEXT_PUBLIC_PACKAGE_ENDPOINT_URL}/api/v1/bookings/${sessionId}`;

export const createFetchBookingStatusRequest = (sessionId: string): RequestInfo =>
	`${process.env.NEXT_PUBLIC_PACKAGE_ENDPOINT_URL}/api/v1/bookings/${sessionId}/status`;

export const createFetchSelectableLuggageRequest = (sessionId: string): RequestInfo =>
	`${process.env.NEXT_PUBLIC_LUGGAGE_ENDPOINT_URL}/api/v1/selectable/luggage/${sessionId}`;

export const createFetchSelectableTransfersRequest = (sessionId: string): RequestInfo =>
	`${process.env.NEXT_PUBLIC_TRANSFER_ENDPOINT_URL}/api/v1/selectable/transfers/${sessionId}`;

export const createFetchSelectableInsurancesRequest = (sessionId: string): RequestInfo =>
	new Request(`${process.env.NEXT_PUBLIC_INSURANCE_ENDPOINT_URL}/api/v1/selectable/insurances/${sessionId}`);

export const usePatchBookingRequest = (sessionId: string, body: State): RequestInfo | undefined => {
	return useMemo(() => {
		if (!body || !body.lastUpdatedState) {
			return undefined;
		}

		switch (body.lastUpdatedState) {
			case 'persons':
				return new Request(
					`${process.env.NEXT_PUBLIC_PACKAGE_ENDPOINT_URL}/api/v1/bookings/${sessionId}/persons`,
					{
						method: 'PATCH',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({ persons: body.persons }),
					},
				);
			case 'insurances':
				return new Request(
					`${process.env.NEXT_PUBLIC_INSURANCE_ENDPOINT_URL}/api/v1/bookings/${sessionId}/insurances`,
					{
						method: 'PATCH',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({ insurances: body.insurances }),
					},
				);
			case 'coupon':
				return new Request(
					`${process.env.NEXT_PUBLIC_COUPON_ENDPOINT_URL}/api/v1/bookings/${sessionId}/coupons`,
					{
						method: 'PATCH',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({ discounts: body.discounts }),
					},
				);
			case 'luggage':
				return new Request(
					`${process.env.NEXT_PUBLIC_LUGGAGE_ENDPOINT_URL}/api/v1/bookings/${sessionId}/luggage`,
					{
						method: 'PATCH',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({ luggage: body.luggage }),
					},
				);
			case 'transfer':
				return new Request(
					`${process.env.NEXT_PUBLIC_TRANSFER_ENDPOINT_URL}/api/v1/bookings/${sessionId}/transfers`,
					{
						method: 'PATCH',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({ transfers: body.transfers }),
					},
				);
			case 'extras':
				return new Request(
					`${process.env.NEXT_PUBLIC_PACKAGE_ENDPOINT_URL}/api/v2/bookings/${sessionId}/extras`,
					{
						method: 'PATCH',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({ extras: body.extras }),
					},
				);

			default:
				throw Error('Patch without a lastUpdateState');
		}
	}, [sessionId, body]);
};

export * from './mapper';
export * from './build';
